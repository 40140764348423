import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// import CustomButton from './CustomButton';
import ContactDialog from './ContactDialog';
import {
  logo, menu, profile, search,
} from '../assets';
import { navlinks } from '../constants';

const Navbar = () => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState('dashboard');
  const [searchValue, setSearchValue] = useState('');
  const [toggleDrawer, setToggleDrawer] = useState(false);

  return (
    <div className=" sticky top-0 z-30">
      <div className="flex md:flex-row flex-col-reverse justify-between mb-[35px] gap-6">
        <div className="lg:flex-1 flex flex-row max-w-[458px] py-2 pl-4 pr-2 h-[52px] bg-[#1c1c24] rounded-[100px]">
          <input
            type="text"
            placeholder="Search Projects"
            className="flex w-full font-epilogue font-normal text-[14px] placeholder:text-[#4b5264] text-white bg-transparent outline-none"
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />

          <div
            className="w-[72px] h-full rounded-[20px] bg-[#16643f] flex justify-center items-center cursor-pointer"
            role="button"
            tabIndex="0"
            onClick={() => {
              setSearchValue('');
            }}
            onKeyDown={
              () => {
                setSearchValue('');
              }
            }
            onTouchStart={
              () => {
                setSearchValue('');
              }
            }
          >
            <img src={search} alt="search" className="w-[15px] h-[15px] object-contain" />
          </div>
        </div>

        <div className="sm:flex hidden flex-row justify-end gap-4">
          {/* <CustomButton
            btnType="button"
            title="Contact"
            styles="bg-[#16643f]"
            handleClick={() => {
              navigate('Contact');
            }}
            role="button"
            tabIndex="0"
          /> */}

          <ContactDialog />

          <Link to="/projects" onClick={() => setIsActive('projects')}>
            <div className="w-[52px] h-[52px] rounded-full bg-[#2c2f32] flex justify-center items-center cursor-pointer">
              <img src={profile} alt="blog" className="w-[60%] h-[60%] object-contain" />
            </div>
          </Link>
        </div>

        {/* Small screen navigation */}
        <div className="sm:hidden flex justify-between items-center relative">
          <div className="w-[40px] h-[40px] rounded-[10px] bg-[#2c2f32] flex justify-center items-center cursor-pointer">
            <img src={logo} alt="user" className="" />
          </div>

          <img
            src={menu}
            alt="menu"
            className="w-[34px] h-[34px] object-contain cursor-pointer"
            onClick={() => setToggleDrawer((prev) => !prev)}
            onKeyDown={() => setToggleDrawer((prev) => !prev)}
            onTouchStart={() => setToggleDrawer((prev) => !prev)}
            role="presentation"
            // tabIndex="0"
          />

          <div className={`absolute top-[60px] right-0 left-0 bg-[#1c1c24] z-10 shadow-secondary py-4 ${!toggleDrawer ? '-translate-y-[100vh]' : 'translate-y-0'} transition-all duration-700`}>
            <ul className="mb-4">
              {navlinks.map((link) => (
                <li
                  key={link.name}
                  className={`flex p-4 ${isActive === link.name && 'bg-[#3a3a43]'} hover:cursor-pointer`}
                  onClick={() => {
                    setIsActive(link.name);
                    setToggleDrawer(false);
                    navigate(link.link);
                  }}
                  onKeyDown={() => {
                    setIsActive(link.name);
                    setToggleDrawer(false);
                    navigate(link.link);
                  }}
                  onTouchStart={() => {
                    setIsActive(link.name);
                    setToggleDrawer(false);
                    navigate(link.link);
                  }}
                  role="presentation"
                  // tabIndex="0"
                >
                  <img
                    src={link.imgUrl}
                    alt={link.name}
                    className={`w-[24px] h-[24px] object-contain ${isActive === link.name ? 'grayscale-0' : 'grayscale'}`}
                  />
                  <p className={`ml-[20px] font-epilogue font-semibold text-[14px] ${isActive === link.name ? 'text-[#16643f]' : 'text-[#fff]'}`}>
                    {link.name}
                  </p>
                </li>
              ))}
            </ul>

            <div className="flex mx-4">
              {/* <CustomButton
                btnType="button"
                title="Contact"
                styles="bg-[#16643f]"
                handleClick={() => {
                  navigate('Contact');
                }}
              /> */}
              <ContactDialog />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
